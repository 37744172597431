<template>
  <div>
    <el-card >
      <el-table :data="billEditTempList" >
        <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
        <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center" ></el-table-column>
        <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center"></el-table-column>
        <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.rate}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('msg.account.amount')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="adjustAmount" :label="$t('msg.account.adjustAmount')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.adjustAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="adjustReason" :label="$t('msg.account.adjustReason')" align="center"></el-table-column>
        <el-table-column prop="finalAmount" :label="$t('msg.account.finalAmount')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.finalAmount}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'

import axios from 'axios'
export default defineComponent({
  name: 'billEditTemp',
  props: {
    myParam: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const billEditTempList = ref()
    onMounted(() => {
      const param = props.myParam
      const reqURL = process.env.VUE_APP_BASE + '/getMyBillEditTemp'
      axios.post(reqURL, param).then((response) => {
        billEditTempList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    return { billEditTempList, props }
  }
})
</script>
